import styled from '@emotion/styled';

/* eslint-disable-next-line */
export interface DashboardLayoutProps {}

const StyledDashboardLayout = styled.div`
  color: pink;
`;

export function DashboardLayout(props: DashboardLayoutProps) {
  return (
    <StyledDashboardLayout>
      <h1>Welcome to DashboardLayout!</h1>
    </StyledDashboardLayout>
  );
}

export default DashboardLayout;

import styled from '@emotion/styled';

/* eslint-disable-next-line */
export interface RequestDomainFormProps {}

const StyledRequestDomainForm = styled.div`
  color: pink;
`;

export function RequestDomainForm(props: RequestDomainFormProps) {
  return (
    <StyledRequestDomainForm>
      <h1>Welcome to RequestDomainForm!</h1>
    </StyledRequestDomainForm>
  );
}

export default RequestDomainForm;

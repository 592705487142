/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { useVirtual } from 'react-virtual';

import { ClientScanData } from '@cdw/client/states';
import { ScanStatus } from '@cdw/common';
import {
  Stack,
  Typography,
  Alert,
  Button,
  Divider,
  Box,
  Grid,
} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DraftsIcon from '@mui/icons-material/Drafts';

/* eslint-disable-next-line */
export interface ScanDetailsProps {
  itemCount?: number;
  scans?: ClientScanData[];
}

export function ScanDetails({ scans = [] }: ScanDetailsProps) {
  const parentRef = React.useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtual({
    size: scans.length,
    parentRef,
    estimateSize: React.useCallback(() => 50, []),
    overscan: 10,
  });

  console.log(`rowVirtualizer.totalSize: ${rowVirtualizer.totalSize}`);

  return (
    <Box
      ref={parentRef}
      style={{
        height: `100%`,
        minHeight: '300px',
        width: `100%`,
        overflow: 'auto',
      }}
    >
      <List
        style={{
          height: `${rowVirtualizer.totalSize}px`,
          width: '100%',
          position: 'relative',
        }}
      >
        {rowVirtualizer.virtualItems.map((virtualRow) => (
          <Stack
            spacing={1}
            direction="column"
            component="li"
            key={virtualRow.index}
            sx={{
              pl: { xs: 2, sm: 4 },
              pr: { xs: 2, sm: 4 },
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '50px',
              maxHeight: '50px',
              transform: `translateY(${virtualRow.start}px)`,
            }}
          >
            <Stack
              direction="row"
              alignItems="baseline"
              alignContent="center"
              spacing={2}
            >
              <Typography
                noWrap
                component="span"
                variant="subtitle1"
                color="text.primary"
              >
                {scans[virtualRow.index].url}
              </Typography>
              {scans[virtualRow.index].exploitDetected ? (
                <Typography
                  component="span"
                  variant="body2"
                  color="error"
                  sx={{ flexGrow: 1 }}
                  gutterBottom
                >
                  {' '}
                  Vulnerability detected!
                </Typography>
              ) : (
                <Box sx={{ flexGrow: 2 }} />
              )}

              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.secondary"
                gutterBottom
              >
                {scans[virtualRow.index].status}
              </Typography>
            </Stack>
            <Divider />
          </Stack>
        ))}
      </List>
    </Box>
  );
}

export default ScanDetails;

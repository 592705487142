import { Dashboard } from '@cdw/client/pages';
import { navigate } from 'gatsby';

/* eslint-disable-next-line */
export interface DashboardProps {}

export default function Component(props: DashboardProps) {
  const onGotoLogin = () => {
    navigate('/login');
  };
  return <Dashboard onLoginRequest={onGotoLogin} />;
}

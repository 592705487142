import { RegisterForm, RegisterFormData } from '@cdw/client/components';
import { firebaseAuth } from '@cdw/client/firebase';
import {
  Alert,
  CircularProgress,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { createUserWithEmailAndPassword, UserCredential } from 'firebase/auth';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getRememberMe, saveRememberMe } from '../helpers';

/* eslint-disable-next-line */
export interface RegisterProps {
  onCancel?: () => void;
  onRegisterSuccess?: (userCredential: UserCredential) => void;
  title?: string;
  description?: string;
}

const DEFAULT_TITLE = 'Register for an account';

const DEFAULT_DESCRIPTION = `
Please enter your email and password to register for an account. 
You will only be able to scan URLs from a domain that matches your email's domain. 
There is a form to request additional domains to scan
`;

export function Register({
  onCancel,
  onRegisterSuccess,
  title = DEFAULT_TITLE,
  description = DEFAULT_DESCRIPTION,
}: RegisterProps) {
  const theme = useTheme();
  const startingEmail = getRememberMe();
  const [user, loading, error] = useAuthState(firebaseAuth);
  const [authError, setAuthError] = React.useState<string | null>(null);

  const backgroundColor =
    theme.palette.mode === 'dark'
      ? theme.palette.grey[900]
      : theme.palette.grey[100];

  const handleEmailPasswordSubmit = ({
    email,
    password,
    rememberMe,
  }: RegisterFormData) => {
    if (rememberMe) {
      saveRememberMe(email);
    } else {
      saveRememberMe('');
    }
    createUserWithEmailAndPassword(firebaseAuth, email, password)
      .then((user) => {
        setAuthError(null);
        onRegisterSuccess && onRegisterSuccess(user);
      })
      .catch((err) => {
        console.error(err);
        if (err.code === 'auth/email-already-in-use') {
          setAuthError('Email already in use');
        } else if (err.code === 'auth/invalid-email') {
          setAuthError('Invalid email');
        } else if (err.code === 'auth/weak-password') {
          setAuthError(
            'Password is too weak. It must be at least 6 characters long'
          );
        } else {
          setAuthError(err.message);
        }
      });
    // registerNewAccount(email, password).then();
  };

  if (loading) {
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={0}
      >
        <Stack>
          <CircularProgress />
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={0}
    >
      <Stack spacing={4} sx={{ p: 2, maxWidth: '600px', backgroundColor }}>
        {error && <Alert severity="error">{error.message}</Alert>}
        {authError && <Alert severity="error">{authError}</Alert>}
        {title && <Typography variant="h1">{title}</Typography>}
        {description && <Typography variant="body1">{description}</Typography>}
        <Stack
          sx={{
            flex: 1,
          }}
          alignContent="center"
          justifyContent="center"
          alignItems="center"
          spacing={4}
        >
          <RegisterForm
            defaultEmail={startingEmail}
            onEmail={handleEmailPasswordSubmit}
            onCancel={onCancel}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}

export default Register;

import { httpsCallable } from 'firebase/functions';
import { firebaseAuth, firestore, functions } from '@cdw/client/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import {
  Stack,
  Typography,
  Divider,
  Alert,
  Grid,
  Box,
  Button,
  Chip,
} from '@mui/material';

export interface LandingPageProps {
  onGotoLogin?: () => void;
  onGotoScan?: () => void;
}

export function LandingPage({ onGotoLogin, onGotoScan }: LandingPageProps) {
  const [user, loading, authError] = useAuthState(firebaseAuth);

  const onStartUrlScan = () => {
    if (!user || loading) {
      onGotoLogin && onGotoLogin();
    } else {
      onGotoScan && onGotoScan();
    }
  };

  return (
    <Stack spacing={2} sx={{ p: { xs: 1, sm: 2 } }}>
      <Stack direction={'row'} justifyContent={'center'}>
        <Button
          variant="contained"
          sx={{ flexGrow: 0 }}
          onClick={() => onStartUrlScan()}
        >
          Start a URL Scan
        </Button>
      </Stack>

      <Divider />

      <Typography variant="h5" component={'h2'}>
        Overview
      </Typography>
      <p>
        This tool is designed to help detect server applications that maybe
        affected by the Log4Shell (CVE-2021-44228, CVE-2021-45046)
        vulnerability.
      </p>
      <p>
        The tool will take a URL from a domain that you are authorized to scan
        and will call it injecting jndi:ldap payloads to check if the
        application is vulnerable to the Log4Shell.
      </p>
      <Stack>
        <Typography variant="subtitle2">Example</Typography>
        <code>{'${jndi:ldap://trace_hash.xpl.log4j.ignw.io/a'}</code>
      </Stack>
      <p>
        If your server is vulnerable, it will try to reach the url provided in
        the payload. We will detect if the domain server had a request - and we
        dead-end the response with a 404, "Not Found".
      </p>
      <p>
        Results take a few seconds to process. We will scan for a vulnerability
        hit for approximately 10 seconds, after which the system assumes that no
        vulnerability was found.
      </p>

      <Divider />

      <Typography variant="h5" component={'h2'}>
        Limitations
      </Typography>
      <p>
        The tool will only scan a domain for which your account is authorized.
        By default, your login email is the starting access domain. To get more
        access to scan domains, please contact your CDW account representative.
        Feel free to create an account for each domain you have an email address
        to scan for.
      </p>
      <p>
        Your email will not be used for marketing purposes. Domain scanning data
        is limited to your account for viewing and is purged after 30 days.
      </p>
    </Stack>
  );
}

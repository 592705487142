/* eslint-disable-next-line */
export interface ScanRequestProps {}

export function ScanRequest(props: ScanRequestProps) {
  return (
    <div>
      <h1>Welcome to ScanRequest!</h1>
    </div>
  );
}

export default ScanRequest;

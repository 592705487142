import { ClientScanData } from '@cdw/client/states';
import { ScanStatus } from '@cdw/common';
import {
  Stack,
  Typography,
  Alert,
  Button,
  Divider,
  Box,
  Grid,
} from '@mui/material';

const statusColors = {
  pending: '#ffc107',
  running: '#28a745',
  complete: '#17a2b8',
  error: '#dc3545',
};

export interface RequestViewProps {
  request: ClientScanData;
  onClear?: () => void;
}

export function RequestView({ request, onClear }: RequestViewProps) {
  const error = false;
  const { url, exploitDetected, completedAt, createdAt } = request;
  const status = request.status as ScanStatus;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">{url}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          {exploitDetected ? 'Exploit detected' : 'No exploits detected'}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          Status: <span style={{ color: statusColors[status] }}>{status}</span>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">Requested: {createdAt}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">Completed: {completedAt}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-end">
          <Button variant="contained" color="primary" onClick={onClear}>
            Clear
          </Button>
        </Box>
      </Grid>
    </Grid>
  );

  return (
    <Stack>
      {error && (
        <Stack direction="row" alignItems="center" alignContent="center">
          <Alert severity="error" sx={{ flexGrow: 2 }}>
            {error}
          </Alert>
        </Stack>
      )}
      <Stack
        direction="row"
        alignItems="baseline"
        alignContent="center"
        spacing={2}
        sx={{ p: 1 }}
      >
        <Typography variant="subtitle2">Status:</Typography>
        <Typography variant="body1" sx={{ color: statusColors[status] }}>
          {status}
        </Typography>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Typography variant="subtitle2">URL:</Typography>
        <Typography variant="body1">{url}</Typography>
        <Box sx={{ flexGrow: 2 }} />
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => onClear && onClear()}
        >
          Clear
        </Button>
      </Stack>
    </Stack>
  );
}

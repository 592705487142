import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';

/* eslint-disable-next-line */
export interface DomainScanFormProps {
  url?: string;
  walkSite?: boolean;
  status?: 'pending' | 'running' | 'complete' | 'error';
  onStartScan?: (url: string, walkSite: boolean) => void;
}

export interface ScanFormInput {
  url: string;
  walkSite: boolean;
}

export function DomainScanForm({
  url,
  walkSite,
  status,
  onStartScan,
}: DomainScanFormProps) {
  const { control, handleSubmit } = useForm<ScanFormInput>({
    defaultValues: { url, walkSite },
  });

  const onSubmit: SubmitHandler<ScanFormInput> = (data) => {
    onStartScan && onStartScan(data.url, data.walkSite);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <Stack direction="row" spacing={2}>
          <Controller
            name="url"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Domain Name"
                sx={{ flexGrow: 2 }}
                disabled={status !== undefined}
              />
            )}
          />
          <Controller
            name="walkSite"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Switch />}
                label="Crawl Site"
                disabled={status !== undefined && status !== 'complete'}
              />
            )}
          />
          <Button variant="contained" type="submit">
            Start Scan
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}

export default DomainScanForm;

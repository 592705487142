/* eslint-disable-next-line */
export interface RequestDomainProps {}

export function RequestDomain(props: RequestDomainProps) {
  return (
    <div>
      <h1>Welcome to RequestDomain!</h1>
    </div>
  );
}

export default RequestDomain;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { ScanStatusView } from '@cdw/client/components';
import {ScanDetailModal} from '../ScanDetailModal/ScanDetailModal'
import { firebaseAuth, firestore, functions } from '@cdw/client/firebase';
import { ClientScanData } from '@cdw/client/states';
import type { ArchiveScanArgs } from '@cdw/common';
import { SCAN_HISTORY_COLLECTION } from '@cdw/settings';
import {
  Alert,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Paper,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { subMinutes } from 'date-fns';
import {
  collection,
  getDocs,
  limit,
  orderBy,
  Query,
  query,
  Timestamp,
  where,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { useObservableState } from 'observable-hooks';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Controller, useForm } from 'react-hook-form';
import { collectionData } from 'rxfire/firestore';
import { debounceTime, Observable, of, timer } from 'rxjs';
import { mapTo, startWith, switchMap } from 'rxjs/operators';

export interface SnackbarMessage {
  message: string;
  key: number;
}

export interface DashboardProps {
  onLoginRequest?: () => void;
}

interface StartScanData {
  url: string;
  deepSearch: boolean;
}

export function Dashboard({ onLoginRequest }: DashboardProps) {
  const [user, loading, authError] = useAuthState(firebaseAuth);
  const { control, handleSubmit, getValues, clearErrors, reset, setError } =
    useForm<StartScanData>({ defaultValues: { url: '', deepSearch: false } });
  // State for user data
  const [scanStaticData, setScanStaticData] = React.useState<ClientScanData[]>(
    []
  );
  const [scanRequest, setScanRequest] = React.useState<StartScanData | null>();
  const [requestError, setRequestError] = React.useState<Error | null>(null);
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [modalData, setModalData] = React.useState<ClientScanData|null>();
  const [snackPack, setSnackPack] = React.useState<readonly SnackbarMessage[]>(
    []
  );
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [messageInfo, setMessageInfo] = React.useState<
    SnackbarMessage | undefined
  >(undefined);

  const [detailOpen, setDetailOpen] = React.useState(false);

  // Function for fetching ClientScanData (non subscription) from Firestore
  const fetchScanData = React.useCallback(async () => {
    if (!user || !user.uid) {
      return [];
    }
    const q = query(
      collection(firestore, SCAN_HISTORY_COLLECTION),
      orderBy('updatedAt', 'desc'),
      where('updatedAt', '<=', Timestamp.fromDate(subMinutes(new Date(), 5))),
      where('uid', '==', user.uid),
      where('isChild', '==', false),
      where('archived', '==', false),
      limit(100)
    );
    const querySnapshot = await getDocs(q).catch((e) => {
      console.error(e);
    });
    if (!querySnapshot) {
      return [];
    }
    const docs = querySnapshot.docs.map((doc) => doc.data() as ClientScanData);
    setScanStaticData(docs);
    return docs;
  }, [user]);

  const startNewScan = React.useCallback(
    (data: StartScanData) => {
      if (user && user.uid) {
        console.log(`Starting a new scan`, data);
        const startScan = httpsCallable(functions, 'startScan');
        startScan(data)
          .then((result) => {
            console.log('Start scan result: ', result.data);
            reset();
            setScanRequest(null);
          })
          .catch((err) => {
            console.error(err);
            setRequestError(err.message);
            setScanRequest(null);
          });
      }
    },
    [user, reset]
  );

  const handleArchiveScan = (archiveArgs: ArchiveScanArgs) => {
    if (user && user.uid) {
      const archiveScan = httpsCallable(functions, 'archiveScan');
      archiveScan(archiveArgs)
        .then((result) => {
          fetchScanData().catch((e) => {
            console.error(e);
          });
        })
        .catch((err) => {
          console.error(err);
          setRequestError(err);
        });
    }
  };
  const handleModalClose = React.useCallback(() => {
    setModalData(null)
    setModalOpen(false)
  }, [setModalOpen, setModalData])

  const handleModalOpen =  React.useCallback((scan: ClientScanData) => {
    return () => {
      setModalOpen(true)
      setModalData(scan)
    }
  }, [setModalOpen, setModalData,])


  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  const onSubmit = (data: StartScanData) => {
    console.log(`Setting scan request`, data);
    setScanRequest(data);
  };

  React.useEffect(() => {
    if (user && user.uid && scanRequest) {
      startNewScan(scanRequest);
    }
  }, [user, scanRequest, startNewScan]);

  React.useEffect(() => {
    if (!loading && authError) {
      clearErrors();
    } else if (!loading && (requestError || authError)) {
      const message = authError?.message || requestError?.message || '';
      setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
    }
  }, [loading, authError, clearErrors, requestError]);

  React.useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      const snack = snackPack[0];
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      if (snack.message) {
        setSnackOpen(true);
      }
    } else if (snackPack.length && messageInfo && snackOpen) {
      // Close an active snack when a new one is added
      setSnackOpen(false);
    }
  }, [snackPack, messageInfo, snackOpen]);

  // Stream of the first 20 scans using RxJS
  const scanStream$ = React.useMemo(() => {
    if (!user || !user.uid) {
      return of([]);
    }
    const scanQuery = query(
      collection(firestore, SCAN_HISTORY_COLLECTION),
      orderBy('updatedAt', 'desc'),
      where('updatedAt', '>', Timestamp.fromDate(subMinutes(new Date(), 5))),
      where('uid', '==', user.uid),
      where('isChild', '==', false),
      where('archived', '==', false),
      limit(20)
    );
    return collectionData<ClientScanData>(
      scanQuery as Query<ClientScanData>
    ).pipe(
      debounceTime(500)
    );
  }, [user]);
  const scanData = useObservableState(scanStream$, []);

  React.useEffect(() => {
    if (user && user.uid) {
      // Gets the scan data for scans that are older than 5 minutes
      fetchScanData();
    }
    return undefined;
  }, [user, fetchScanData]);

  if (loading) {
    return null;
  }

  if (authError || (!user && !loading)) {
    return (
      <Stack spacing={2} sx={{ padding: 2 }}>
        <Typography variant="h6">
          You must be logged in to access this page.
        </Typography>
        <Stack direction="row">
          <Button
            variant="contained"
            onClick={() => onLoginRequest && onLoginRequest()}
          >
            Login
          </Button>
        </Stack>
      </Stack>
    );
  }

  return (
    <>
    <Stack spacing={2} sx={{ padding: { xs: 1, sm: 2 } }}>
      <Paper
        elevation={3}
        sx={{
          padding: { xs: 1, sm: 2 },
          pb: { xs: 1, sm: 2 },
        }}
      >
        <Typography variant="h2">Start a new scan</Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack
            direction={'column'}
            sx={{ pt: { xs: 1, sm: 2 }, pb: { xs: 1, sm: 2 } }}
          >
            <Controller
              name="url"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  sx={{ flexGrow: 2 }}
                  variant="outlined"
                  id="url"
                  label={`URL to search`}
                  type="url"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={'Please enter a valid URL to scan'}
                />
              )}
              rules={{
                required: 'URL required',
              }}
            />
            <Stack
              spacing={2}
              direction={'row'}
              alignItems="center"
              alignContent="center"
              justifyContent="flex-end"
            >
              <Controller
                name="deepSearch"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        // value={value}
                        checked={value}
                        onChange={onChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="Deep Scan"
                  />
                )}
              />
              <Button
                variant="contained"
                type="submit"
                disabled={!!scanRequest}
              >
                Start Scan
              </Button>
            </Stack>
          </Stack>
        </form>
      </Paper>

      {!scanData || scanData.length === 0 ? null : (
        <Stack>
          <Typography variant="h3" sx={{ p: 1 }}>
            Current Scans
          </Typography>
          {scanData.length < 1 ? null : (
            <Stack spacing={1}>
              {scanData.map((item) => (
                <ScanStatusView
                  key={item.traceId + '_' + item.instanceId}
                  scanEvent={item}
                  instanceId={item.instanceId}
                  onArchive={handleArchiveScan}
                  onClick={handleModalOpen(item)}
                />
              ))}
              <Divider />
            </Stack>
          )}
        </Stack>
      )}

      {!scanStaticData || !scanStaticData.length ? null : (
        <Stack>
          <Divider />
          <Typography variant="h3" sx={{ p: 1 }}>
            Previous Scans
          </Typography>
          {scanStaticData.length < 1 ? null : (
            <Stack spacing={1}>
              {scanStaticData.map((item) => (
                <ScanStatusView
                  key={item.traceId + '_' + item.instanceId}
                  scanEvent={item}
                  instanceId={item.instanceId}
                  onArchive={handleArchiveScan}
                  onClick={handleModalOpen(item)}
                />
              ))}
            </Stack>
          )}
        </Stack>
      )}

      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        open={snackOpen}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleClose}
        TransitionProps={{ onExited: handleExited }}
        message={messageInfo ? messageInfo.message : undefined}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {messageInfo && messageInfo.message}
        </Alert>
      </Snackbar>
    </Stack>
      {modalData && <ScanDetailModal open={modalOpen} scan={modalData} onClose={handleModalClose}/>}
    </>
  );
}

export default Dashboard;

function transformTypingStatus(event$: Observable<boolean>) {
  return event$.pipe(
    switchMap(() => timer(1000).pipe(mapTo(false), startWith(true)))
  );
}

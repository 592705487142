import { z } from 'zod';

export const zFirebaseTimestamp = z.object({
  seconds: z.number(),
  nanoseconds: z.number(),
  toDate: z.function().returns(z.date()),
  toMillis: z.function().returns(z.number()),
  valueOf: z.function().returns(z.string()),
});

// const timestampToString = z.preprocess((val) => {
//   if (
//     val instanceof Object &&
//     'seconds' in val &&
//     'nanoseconds' in val &&
//     'toDate' in val
//   ) {
//     return (val as { seconds: number; nanoseconds: number; toDate: () => Date })
//       .toDate()
//       .toISOString();
//   }
//   if (val instanceof Date) {
//     return val.toISOString();
//   }
//   return val;
// }, z.string());

export const zScanStatus = z.enum(['pending', 'running', 'complete', 'error']);
export type ScanStatus = z.infer<typeof zScanStatus>;

export const zUserData = z.object({
  email: z.string(),
  domains: z.array(z.string()),
  admin: z.boolean(),
  fullAccess: z.boolean(),
  createdAt: z.string(),
  updatedAt: z.string(),
});
export type UserData = z.infer<typeof zUserData>;

export const zStartScanArgs = z.object({
  url: z.string(),
  deepSearch: z.boolean().optional().default(false),
});
export type StartScanArgs = z.infer<typeof zStartScanArgs>;

export const zGetScanDetailsArgs = z.object({
  instanceId: z.string(),
  traceId: z.string(),
  uid: z.string().optional(),
});
export type GetScanDetailsArgs = z.infer<typeof zStartScanArgs>;

export const zArchiveScanArgs = z.object({
  instanceId: z.string(),
  traceId: z.string(),
  archived: z.boolean().optional().default(true),
});
export type ArchiveScanArgs = z.infer<typeof zArchiveScanArgs>;

export const zScanData = z.object({
  uid: z.string(),
  email: z.string().email({ message: 'Invalid email address' }),
  url: z.string().url({ message: 'Invalid url' }),
  status: zScanStatus,
  exploitDetected: z.union([z.boolean(), z.null()]).optional(),
  instanceId: z.string(),
  traceId: z.string(),
  deepSearch: z.boolean().optional().default(false),
  createdAt: z.string(),
  requestedAt: z.string(),
  updatedAt: z.string(),
  isChild: z.boolean().optional().default(false),
  parentUrl: z.string().optional(),
  parentInstanceId: z.string().optional(),
  parentTraceId: z.string().optional(),
  responseString: z.string().optional(),
  archived: z.boolean().optional().default(false),
});
export type ScanData = z.infer<typeof zScanData>;

export const zScanMessage = zScanData.omit({
  status: true,
  createdAt: true,
  updatedAt: true,
  requestedAt: true,
});
export type ScanMessage = z.infer<typeof zScanMessage>;

export const zScanUpdateMessage = zScanMessage.extend({
  updatedAt: z.string(),
});
export type ScanUpdateMessage = z.infer<typeof zScanUpdateMessage>;

export const zScanMonitorMessage = zScanMessage.extend({
  monitorStartTime: z.string(),
});
export type ScanMonitorMessage = z.infer<typeof zScanMonitorMessage>;

export const zScanResultMessage = zScanUpdateMessage.extend({
  // exploitDetected: z.boolean(),
});
export type ScanResultMessage = z.infer<typeof zScanResultMessage>;

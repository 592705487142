import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Slide from '@mui/material/Slide';
import { Header } from '@cdw/client/components';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { themeDark, themeLight } from '@cdw/client/themes';
import { useMediaQuery } from '@mui/material';

function HideOnScroll({ children }) {
  // const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    // target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      <div>{children}</div>
    </Slide>
  );
}

export default function Layout({ children, ...props }) {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  return (
    <ThemeProvider
      theme={createTheme(prefersDarkMode ? themeDark : themeLight)}
    >
      <CssBaseline />
      <Header />
      <Toolbar />
      {children}
    </ThemeProvider>
  );
}

export const PROJECT_ID =
  process.env.PROJECT_ID ||
  process.env.GCLOUD_PROJECT ||
  process.env.GOOGLE_CLOUD_PROJECT;

export const BASE_URL =
  process.env.BASE_URL || PROJECT_ID === 'log4j-vul-dev'
    ? 'https://forgefire.io/log4j'
    : 'https://scan.ignw.io.';

// Global function timeout
export const FUNCTION_TIMEOUT_SECONDS = parseInt(
  process.env.FUNCTION_TIMEOUT_SECONDS || `${5 * 60}`
);

// Timeout defaults to 5 seconds
export const STREAM_DETECT_TIMEOUT = process.env.STREAM_DETECT_TIMEOUT
  ? parseInt(process.env.STREAM_DETECT_TIMEOUT)
  : 15; /// 5

export const MAX_MONITOR_INSTANCES = process.env.MAX_MONITOR_INSTANCES
  ? parseInt(process.env.MAX_MONITOR_INSTANCES)
  : 1;

// In seconds
export const MAX_MONITOR_LIFESPAN = process.env.MAX_MONITOR_LIFESPAN
  ? parseInt(process.env.MAX_MONITOR_LIFESPAN)
  : 900;

// Pagination defaults
export const DEFAULT_PAGE_SIZE = process.env.DEFAULT_PAGE_SIZE
  ? parseInt(process.env.DEFAULT_PAGE_SIZE)
  : 20;

export const SETTINGS_EVENTS_COLLECTION =
  process.env.SETTINGS_EVENTS_COLLECTION || 'settings';

export const SCAN_EVENTS_COLLECTION =
  process.env.SCAN_EVENTS_COLLECTION || 'scans';

export const SCAN_HISTORY_COLLECTION =
  process.env.SCAN_HISTORY_COLLECTION || 'scan_history';

export const USER_SCANS_COLLECTION =
  process.env.USER_SCANS_COLLECTION || 'user_scans';

export const USER_COLLECTION = process.env.USER_COLLECTION || 'users';

export const DNS_SCANNER_DOMAIN =
  process.env.DNS_SCANNER_DOMAIN || PROJECT_ID === 'log4j-vul-dev'
    ? 'xpl.log4j.forgefire.io'
    : 'pin.scan.ignw.io';

export const PUBSUB_TOPICS = {
  SCAN_REQUEST: 'scan-request',
  SCAN_DEEP: 'scan-deep',
  SCAN_UPDATE: 'scan-update',
  SCAN_MONITOR: 'scan-monitor',
  SCAN_RESULTS: 'scan-results',
};

// Firebase Database URL
let databaseURL = process.env.FIREBASE_DATABASE_URL;
if (!databaseURL && process.env.FIREBASE_CONFIG) {
  try {
    const config = JSON.parse(process.env.FIREBASE_CONFIG);
    databaseURL = config.databaseURL;
  } catch (e) {
    console.error(e);
  }
}
export const DATABASE_URL = databaseURL;

export const HISTORY_CLEAN_TIME =
  process.env.HISTORY_CLEAN_TIME && parseInt(process.env.HISTORY_CLEAN_TIME) > 0
    ? parseInt(process.env.HISTORY_CLEAN_TIME)
    : 3600;

import { ClientScanData } from '@cdw/client/states';
import type { ScanStatus } from '@cdw/common';
import { Button, Chip, Divider, Grid, Stack, Typography } from '@mui/material';

const statusColors = {
  pending: '#ffc107',
  running: '#28a745',
  complete: '#17a2b8',
  error: '#dc3545',
};
export interface ScanStatusViewProps {
  scanEvent: ClientScanData;
  instanceId: string;
  onClick: () => void
  onArchive?: (args: {
    instanceId: string;
    traceId: string;
    archived: boolean;
  }) => void;
  onDetails?: (scanEvent: ClientScanData) => void;
}

export function ScanStatusView({
  instanceId,
  scanEvent,
  onArchive,
  onDetails,
  onClick
}: ScanStatusViewProps) {
  const { url, completedAt, exploitDetected } = scanEvent;
  const status = scanEvent.status as ScanStatus;

  const ColorChip = () => (
    <Chip
      sx={{ width: '100%' }}
      label={
        status === 'complete'
          ? exploitDetected
            ? 'Vulnerable'
            : 'Passed'
          : status
      }
      color={
        status === 'pending'
          ? 'warning'
          : status === 'running'
          ? 'info'
          : exploitDetected
          ? 'error'
          : 'success'
      }
    />
  );

  return (
    <Grid container onClick={onClick}>
      <Grid item xs={4} sm={2}>
        <Stack
          direction="row"
          alignItems="center"
          alignContent="center"
          sx={{ height: '100%', p: { xs: 1, sm: 2 } }}
        >
          <ColorChip />
        </Stack>
      </Grid>
      <Grid item xs={4} sm={8}>
        <Stack>
          <Typography variant="h6">{url}</Typography>
          <Typography variant="subtitle2" color="gray">
            {scanEvent?.createdAt?.toDate()?.toLocaleString()}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={4} sm={2}>
        <Stack
          spacing={2}
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ height: '100%', p: { xs: 1, sm: 2 } }}
        >
          {!onDetails ? null : (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => onDetails && onDetails(scanEvent)}
            >
              Details
            </Button>
          )}
          {!onArchive ? null : (
            <Button
              variant="outlined"
              color="secondary"
              onClick={(event) => {
                event.stopPropagation();
                return onArchive &&
                onArchive({
                  instanceId: scanEvent.instanceId,
                  traceId: scanEvent.traceId,
                  archived: true,
                  })
                }
              }
            >
              Remove
            </Button>
          )}
        </Stack>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
}

export default ScanStatusView;

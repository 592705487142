/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { useVirtual } from 'react-virtual';
import {forEach, keys, startCase} from 'lodash';

import { ClientScanData } from '@cdw/client/states';
import { ScanStatus } from '@cdw/common';
import {
  Stack,
  Typography,
  Alert,
  Dialog,
  Button,
  Divider,
  Box,
  Grid,
  Chip,
} from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import { CSVLink } from 'react-csv';
import { functions } from '@cdw/client/firebase';
import { ScanDetails } from '@cdw/client/components';

export interface ScanDetailModalProps {
  open?: boolean;
  scan: ClientScanData;
  handleArchiveScan?: () => void;
  onClose?: () => void;
}

export function ScanDetailModal({
  open=false,
  scan,
  handleArchiveScan,
  onClose,
}: ScanDetailModalProps) {
  const { url, completedAt, exploitDetected } = scan;
  const status = scan.status as ScanStatus;
  const ColorChip = () => (
    <Chip
      sx={{ width: '100%' }}
      label={
        status === 'complete'
          ? exploitDetected
            ? 'Vulnerable'
            : 'Passed'
          : status
      }
      color={
        status === 'pending'
          ? 'warning'
          : status === 'running'
          ? 'info'
          : exploitDetected
          ? 'error'
          : 'success'
      }
    />
  );

  // State that will handle a fetch status lifecycle
  const [fetchStatus, setFetchStatus] = useState<
    'idle' | 'loading' | 'loaded' | 'error'
  >('idle');
  // The scan data state
  const [scanData, setScanData] = useState<ClientScanData[]>();
  // The scan error state
  const [scanError, setScanError] = useState<Error>();
  const getScan = React.useCallback(() => {
    return httpsCallable<Partial<ClientScanData>, ClientScanData[]>(
      functions,
      'getScanDetails'
    );
  }, []);

  // An effect that uses the scan prop to fetch the scan data
  React.useEffect(() => {
    if (scan) {
      setFetchStatus('loading');
      getScan()(scan)
        .then((result) => {
          console.log('result', result);
          setScanError(undefined);
          setScanData(result.data);
          setFetchStatus('loaded');
        })
        .catch((err) => {
          console.error(err);
          setScanError(err);
          setScanData(undefined);
          setFetchStatus('error');
        });
    }
  }, [scan, getScan]);
  const getCSVDataHeaders = React.useCallback(() => {
    const fields = [ //TODO: find cleaner way of grabbing type off a TypeScript type for header transformations
      'uid',
      'email',
      'url',
      'status',
      'instanceId',
      'traceId',
      'deepSearch',
      'createdAt',
      'updatedAt',
      'completedAt',
      'exploitDetected',
      'isChild',
      'archived',
      'parentUrl',
      'parentInstanceId',
      'parentTraceId'].map((value)=> {
        return {label: startCase(value), key: value}
    })

    return fields;
  }, []);

  const getCSVData = React.useCallback((data) => {
    return data.map((entry: ClientScanData) => {
      const dataObject: Record<string, any> = entry && {...entry};
      dataObject['createdAt'] = new Date(dataObject['createdAt']?._nanoseconds).toLocaleString();

      dataObject['updatedAt'] = new Date(dataObject['updatedAt']._nanoseconds).toLocaleString();
      dataObject['completedAt'] = dataObject['completedAt'] ?
        new Date(dataObject['completedAt']?._nanoseconds).toLocaleString() : 'INCOMPLETE';
      return dataObject;
    })
  }, [])
  if (!scan) {
    return null;
  }

  return (
    <Dialog
      open={open}
      fullScreen
      onClose={onClose}
      >
      <Grid container>
        <Grid item xs={4} sm={2}>
          <Stack
            direction="row"
            alignItems="center"
            alignContent="center"
            sx={{ height: '100%', p: { xs: 1, sm: 2 } }}
          >
            <ColorChip />
          </Stack>
        </Grid>
        <Grid item xs={4} sm={8}>
          <Stack>
            <Typography variant="h6">{url}</Typography>
            <Typography variant="subtitle2" color="gray">
              {scan.createdAt?.toDate()?.toLocaleString()}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={4} sm={2}>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            sx={{ height: '100%', p: { xs: 1, sm: 2 } }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => onClose && onClose()}
            >
              Close
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          {fetchStatus === 'loading' ? (
            (`Loading...` as any)
          ) : !scanData ? null : (
            <Box>
              {fetchStatus === 'loaded' &&    <Stack spacing={2}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                sx={{ height: '100%', p: { xs: 1, sm: 2 } }}
                > <CSVLink headers={getCSVDataHeaders()} data={scanData.length ? getCSVData(scanData) : [scan]}
                                                    style={{textDecoration: 'none', float: 'right'}}>

                <Button
                  variant="outlined"
                  color="primary"
                >
                  Download
                </Button>
              </CSVLink>
              </Stack>}
              <ScanDetails scans={scanData} />
            </Box>
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default ScanDetailModal;

import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Slide from '@mui/material/Slide';
import { Stack, styled, useTheme } from '@mui/material';
import CDWLogo from '../Logos/CDWlogo';
import { red, blue, green } from '@mui/material/colors';
/* eslint-disable-next-line */
export interface HeaderProps {}

const AppTitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.2rem',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '2.8rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '3.1rem',
  },
}));

export function Header(props: HeaderProps) {
  const theme = useTheme();
  return (
    <AppBar
      sx={{
        backgroundColor: theme.palette.mode === 'dark' ? '#CC0000' : 'dimgrey',
      }}
    >
      <Toolbar>
        <Typography variant="h6" component="div">
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={{ xs: 1, sm: 2 }}
          >
            <CDWLogo sx={{ width: '40px', height: '40px', color: '#fff' }} />
            <AppTitle variant="h1" sx={{ flexGrow: 1 }} noWrap>
              Log4j Vulnerability Detector
            </AppTitle>
          </Stack>
        </Typography>
      </Toolbar>
    </AppBar>
  );
}

export default Header;

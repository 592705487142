import { regexEmail } from '@cdw/common';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { styled, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import OutlinedInput from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

const LinkButton = styled(Link)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(13),
  fontWeight: theme.typography.fontWeightRegular,
  color: theme.palette.secondary.main,
  textTransform: 'none',
}));

export interface RegisterFormData {
  email: string;
  password: string;
  firstName: string;
  givenName: string;
  rememberMe: boolean;
}

/* eslint-disable-next-line */
export interface RegisterFormProps {
  defaultEmail?: string;
  onEmail?: (result: RegisterFormData) => void;
  onSocial?: (type: string) => void;
  onCancel?: (email?: string) => void;
}

export function RegisterForm({
  defaultEmail = '',
  onEmail,
  onSocial,
  onCancel,
}: RegisterFormProps) {
  const theme = useTheme();
  const [isPassVis, setIsPassVis] = React.useState(false);
  const { control, handleSubmit, getValues } = useForm<RegisterFormData>();
  const onSubmit = (data: RegisterFormData) => onEmail && onEmail(data);

  const handleClickShowPassword = () => {
    setIsPassVis(!isPassVis);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleSocialSubmit = (type: string) => {
    onSocial && onSocial(type);
  };

  const handleCancel = () => {
    onCancel && onCancel(getValues().email);
  };

  return (
    <Stack
      spacing={4}
      sx={{
        minWidth: {
          xs: '400px',
          sm: '440px',
          md: '500px',
        },
        color: theme.palette.text.primary,
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <Controller
            name="email"
            control={control}
            defaultValue={defaultEmail}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                id="email"
                label={'Email'}
                type="email"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
            rules={{
              required: `Email required`,
              pattern: regexEmail,
            }}
          />
          <Controller
            name="password"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  {'Password'}
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={isPassVis ? 'text' : 'password'}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {isPassVis ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
            )}
            rules={{ required: `Password required` }}
          />
          <Stack
            direction="row-reverse"
            spacing={2}
            sx={{
              display: 'flex',
            }}
          >
            <Button variant="contained" type="submit">
              Create account
            </Button>
            <Button variant="outlined" onClick={() => handleCancel()}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
}

export default RegisterForm;

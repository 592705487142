import * as React from 'react';
import { LandingPage } from '@cdw/client/pages';
import { navigate } from 'gatsby';

export default function Component() {
  const onGotoLogin = () => {
    navigate('/login');
  };
  const onGotoScan = () => {
    navigate('/dashboard');
  };

  return (
    <LandingPage
      {...{
        onGotoLogin,
        onGotoScan,
      }}
    />
  );
}

import { Register } from '@cdw/client/pages';
import { navigate } from 'gatsby';

const RegisterPage = () => {
  const onRegisterSuccess = () => {
    console.log('trying to navigate to /dashboard');
    navigate('/dashboard', { replace: true });
  };
  const onCancel = () => {
    navigate('/', { replace: true });
  };
  return <Register {...{ onRegisterSuccess, onCancel }} />;
};
export default RegisterPage;

import { Login } from '@cdw/client/pages';
import { Container } from '@mui/material';
import { navigate } from 'gatsby';

const LoginPage = () => {
  const onCreateAccount = () => {
    console.log('trying to navigate to /register');
    navigate('/register');
  };
  const onForgotPassword = () => {
    navigate('/forgot-password');
  };
  const onSignInSuccess = () => {
    navigate('/dashboard', { replace: true });
  };

  return (
    <Container sx={{ mt: 2 }}>
      <Login
        {...{
          onCreateAccount,
          onForgotPassword,
          onSignInSuccess,
        }}
      />
    </Container>
  );
};
export default LoginPage;

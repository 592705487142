import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import OutlinedInput from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { styled, useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';
import { regexEmail } from '@cdw/common';

const LinkButton = styled(Link)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(13),
  fontWeight: theme.typography.fontWeightRegular,
  color: theme.palette.secondary.main,
  textTransform: 'none',
  cursor: 'pointer',
}));

/* eslint-disable-next-line */
export interface LoginFormProps {
  defaultEmail?: string;
  onEmail?: (data: LoginInFormData) => void;
  onSocial?: (type: string) => void;
  onCreateAccount?: (email: string) => void;
  onForgotPassword?: (email: string) => void;
}

export interface LoginInFormData {
  email: string;
  password: string;
  rememberMe: boolean;
}

export function LoginForm({
  defaultEmail = '',
  onEmail,
  onSocial,
  onCreateAccount,
  onForgotPassword,
}: LoginFormProps) {
  const theme = useTheme();
  const [isPassVis, setIsPassVis] = React.useState(false);
  const { control, handleSubmit, getValues } = useForm<LoginInFormData>();
  const onSubmit = (data: LoginInFormData) => onEmail && onEmail(data);

  const handleClickShowPassword = () => {
    setIsPassVis(!isPassVis);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleSocialSubmit = (type: string) => {
    onSocial && onSocial(type);
  };

  const handleCreateAccount = () => {
    const { email } = getValues();
    onCreateAccount && onCreateAccount(email);
  };

  const handleForgotPassword = () => {
    const { email } = getValues();
    onForgotPassword && onForgotPassword(email);
  };

  return (
    <Stack
      spacing={4}
      sx={{
        minWidth: {
          xs: '400px',
          sm: '440px',
          md: '500px',
        },
        color: theme.palette.text.primary,
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Controller
            name="email"
            control={control}
            defaultValue={defaultEmail}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                id="email"
                label={`Email`}
                type="email"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
            rules={{
              required: 'Email required',
              pattern: regexEmail,
            }}
          />
          <Controller
            name="password"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  {`Password`}
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={isPassVis ? 'text' : 'password'}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {isPassVis ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={'Password'}
                />
              </FormControl>
            )}
            rules={{ required: 'Password required' }}
          />

          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: '100%' }}
          >
            <Controller
              name="rememberMe"
              control={control}
              defaultValue={true}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControlLabel
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontSize: theme.typography.pxToRem(13),
                    },
                  }}
                  control={
                    <Checkbox
                      defaultChecked
                      value={value}
                      onChange={onChange}
                      sx={{
                        color: theme.palette.secondary.main,
                        '&.Mui-checked': {
                          color: theme.palette.secondary.main,
                        },
                      }}
                      size="small"
                    />
                  }
                  label={'Remember me'}
                />
              )}
            />

            <LinkButton
              underline="hover"
              onClick={() => handleForgotPassword()}
            >
              {`Forgot password?`}
            </LinkButton>
          </Stack>
          <Button variant="contained" type="submit">
            {`Sign in`}
          </Button>
          <Typography
            variant="body2"
            sx={{ fontSize: theme.typography.pxToRem(13), alignSelf: 'center' }}
          >
            {`Don't have an account?`}
            <LinkButton
              underline="hover"
              sx={{ ml: 1 }}
              onClick={() => handleCreateAccount()}
            >
              {'Get started'}
            </LinkButton>
          </Typography>
        </Stack>
      </form>
    </Stack>
  );
}

export default LoginForm;

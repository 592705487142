import { LoginForm, LoginInFormData } from '@cdw/client/components';
import { firebaseAuth } from '@cdw/client/firebase';
import { Alert, Stack, Typography, useTheme } from '@mui/material';
import { signInWithEmailAndPassword, UserCredential } from 'firebase/auth';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getRememberMe, saveRememberMe } from '../helpers';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface LoginProps {
  onCreateAccount?: () => void;
  onForgotPassword?: () => void;
  onSignInSuccess?: (userCredential: UserCredential) => void;
  title?: string;
  description?: string;
}

const DEFAULT_TITLE = 'Login to your account';

const DEFAULT_DESCRIPTION = ``;

export function Login({
  onCreateAccount,
  onForgotPassword,
  onSignInSuccess,
  title = DEFAULT_TITLE,
  description = DEFAULT_DESCRIPTION,
}: LoginProps) {
  const theme = useTheme();
  const startingEmail = getRememberMe();

  const [user, loading, error] = useAuthState(firebaseAuth);
  const [authError, setAuthError] = React.useState<string | null>(null);

  const backgroundColor =
    theme.palette.mode === 'dark'
      ? theme.palette.grey[900]
      : theme.palette.grey[100];

  const handleEmailPasswordSubmit = ({
    email,
    password,
    rememberMe,
  }: LoginInFormData) => {
    if (rememberMe) {
      saveRememberMe(email);
    } else {
      saveRememberMe('');
    }
    signInWithEmailAndPassword(firebaseAuth, email, password)
      .then((user) => {
        setAuthError(null);
        onSignInSuccess && onSignInSuccess(user);
      })
      .catch((err) => {
        console.error({ message: err.message, code: err.code });
        if (err.code === 'auth/user-not-found') {
          setAuthError(
            'We could not find an account with that email address. Please try again.'
          );
        } else if (err.code === 'auth/wrong-password') {
          setAuthError(
            'The password you entered is incorrect. Please try again.'
          );
        } else {
          setAuthError(err.message);
        }
      });
  };

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={0}
    >
      <Stack spacing={4} sx={{ p: 2, maxWidth: '600px', backgroundColor }}>
        {error && <Alert severity="error">{error.message}</Alert>}
        {authError && <Alert severity="error">{authError}</Alert>}
        {title && <Typography variant="h1">{title}</Typography>}
        {description && <Typography variant="body1">{description}</Typography>}
        <Stack
          sx={{
            flex: 1,
          }}
          alignContent="center"
          justifyContent="center"
          alignItems="center"
          spacing={4}
        >
          <LoginForm
            defaultEmail={startingEmail}
            onEmail={handleEmailPasswordSubmit}
            onCreateAccount={onCreateAccount}
            onForgotPassword={onForgotPassword}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
